import axios, { Options } from "redaxios";
import { User } from "./types";

export default class Api {
  private options: Options;

  constructor() {
    this.options = {
      withCredentials: true,
      headers: {},
    };
  }

  login({ username, password }: { username: string; password: string }) {
    return axios.post<{ token: string }>(
      `/api/login`,
      {
        username,
        password,
      },
      this.options
    );
  }

  logout() {
    return axios.post(`/api/logout`, null, this.options);
  }

  me() {
    return axios.get<User>(`/api/me`, this.options);
  }
}
