import { useRouter } from "next/router";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Api from "../api";
import { Context, User } from "./context";

/* eslint-disable-next-line */
export interface Props {}

export function Provider({ children }: PropsWithChildren<Props>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const router = useRouter();

  const api = new Api();

  const fetchUser = useCallback(async () => {
    try {
      const response = await api.me();
      setUser(response.data);
      setIsLoading(false);
    } catch (e: any) {
      if (e?.status !== 401) {
        console.log(e);
        setError(e?.data);
      }

      setIsLoading(false);
    }
  }, []);

  const login = useCallback(
    async (data: { username: string; password: string }) => {
      return api.login(data).then(() => {
        fetchUser().then(() => {
          // console.log(router.query);
          // if (router.query["returnTo"]) {
          //   window.location.href = router.query["returnTo"] as string;
          // } else {
          //   window.location.href = "/";
          // }
        });
      });
    },
    []
  );

  const logout = useCallback(async () => {
    api.logout().then(() => {
      window.location.href = "/login";
    });
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  const value = useMemo(() => ({ user, login, logout }), [user, login, logout]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        loading...
      </div>
    );
  }
  if (error) return <p>Error {error?.message}</p>;

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
