import { BreakpointSize } from "@dvs/data-access";

export const BREAKPOINTS: Record<BreakpointSize, number> = {
  DEFAULT: 960,
  XS: 320,
  SM: 480,
  MD: 720,
  LG: 960,
  XL: 1024,
};
