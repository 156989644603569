/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from "react";
import { User } from "../types";

type Props = {
  user: User | null;
  login: (data: { username: string; password: string }) => Promise<any>;
  logout: () => void;
};
export const Context = createContext<Props | undefined>(undefined);
export type { Props as ContextProps, User };
