import * as React from "react";
import { useAuth } from "./hooks";
export function WithAuthRedirect({
  children,
  authenticate,
  redirectAuthenticatedTo,
}: {
  children: React.ReactNode;
  authenticate?: boolean;
  redirectAuthenticatedTo?: string;
}) {
  const { user } = useAuth();

  if (typeof window === "undefined") return null;

  if (user) {
    if (redirectAuthenticatedTo) {
      if (["Admin", "SuperAdmin"].includes(user.role ?? "")) {
        window.location.replace("/dashboard");
        return null;
      }
      window.location.replace("/logout");
      return null;
    }
  } else {
    if (authenticate) {
      const url = new URL("/login", window.location.href);
      url.searchParams.append("returnTo", window.location.pathname);
      window.location.replace(url.toString());
      return null;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
