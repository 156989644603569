/* eslint-disable @typescript-eslint/ban-types */
import { BreakpointSize } from "@dvs/data-access";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { BREAKPOINTS } from "../../const/responsive";
import { Context } from "./context";

const smallScreenSize = 458;

type Props = {};

export function Provider({ children }: PropsWithChildren<Props>) {
  const [breakpoint, setBreakpoint] = useState<BreakpointSize>(
    BreakpointSize.Default
  );

  const [isSmallScreen, setIsSmallScreen] = useState(true);

  const handleScreenSizeCheck = useCallback((matches) => {
    if (matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, []);

  const handleWindowResize = useCallback(() => {
    const breakPointSize =
      Object.values(BreakpointSize).find(
        (k) => window.matchMedia(`(min-width: ${BREAKPOINTS[k]}px)`).matches
      ) ?? BreakpointSize.Default;
    setBreakpoint(breakPointSize);
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const mwMM = window.matchMedia(`(max-width: ${smallScreenSize}px)`);
    window.addEventListener("resize", handleWindowResize);
    const checkScreen = (e: MediaQueryListEvent) => {
      handleScreenSizeCheck(e.matches);
    };
    if (mwMM) mwMM.addEventListener("change", checkScreen);

    handleScreenSizeCheck(mwMM.matches);
    handleWindowResize();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      if (mwMM) mwMM.removeEventListener("change", checkScreen);
    };
  }, [handleScreenSizeCheck, handleWindowResize]);

  return (
    <Context.Provider value={{ breakpoint, isSmallScreen }}>
      {children}
    </Context.Provider>
  );
}
