import { UserProvider } from "@auth0/nextjs-auth0";
import {
  AuthProvider,
  NextPageWithAuth,
  urqlClient,
  WithAuthRedirect,
} from "@dvs/auth";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode } from "react";
import { Provider } from "urql";
import { ResponsiveProvider } from "../providers";
import "../styles.css";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithAuth & NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <Head>
        <title>Imagine - Dumbrava Vlăsiei</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"
        ></meta>
        <meta
          name="theme-color"
          content="#FFFCF4"
          media="(prefers-color-scheme: light)"
        ></meta>
        <meta
          name="theme-color"
          content="#0A25AF"
          media="(prefers-color-scheme: dark)"
        ></meta>
      </Head>
      {/* <UserProvider> */}
      <Provider value={urqlClient}>
        {/* <AuthProvider> */}
        {/* <WithAuthRedirect
              authenticate={Component.authenticate}
              redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
            > */}
        <ResponsiveProvider>
          <main className="app relative">
            {getLayout(<Component {...pageProps} />)}
          </main>
        </ResponsiveProvider>
        {/* </WithAuthRedirect> */}
        {/* </AuthProvider> */}
      </Provider>
      {/* </UserProvider> */}
    </>
  );
}

export default CustomApp;
