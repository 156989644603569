import {
  cacheExchange,
  CombinedError,
  createClient,
  dedupExchange,
  errorExchange,
  // cacheExchange,
  fetchExchange,
} from "urql";
import Api from "./api";

export async function onUrqlError(error: CombinedError) {
  const isAuthError = error.graphQLErrors.some(
    (e) => e.extensions?.["code"] === "UNAUTHENTICATED"
  );

  const api = new Api();

  if (isAuthError) {
    await api.logout();
    const url = new URL("/login", window.location.href);
    url.searchParams.append("returnTo", window.location.pathname);
    window.location.replace(url.toString());
  }
}

export function createUrqlClient() {
  const exchanges = [
    dedupExchange,
    errorExchange({
      onError: onUrqlError,
    }),
    fetchExchange,
  ];
  return createClient({
    url: `${process.env["NEXT_PUBLIC_API_URL"]}/graphql`,
    exchanges,
    fetchOptions: {
      credentials: "include",
      headers: {},
    },
  });
}

export const urqlClient = createClient({
  url: `${process.env["NEXT_PUBLIC_API_URL"]}/graphql`,
  exchanges: [
    dedupExchange,
    cacheExchange,
    errorExchange({
      onError: onUrqlError,
    }),
    fetchExchange,
  ],
  fetchOptions: {
    credentials: "include",
  },
});
